export const REDUCE_BROKER_ELEMENT = 'REDUCE_BROKER_ELEMENT';
export const REDUCE_BROKER = 'REDUCE_BROKER';
export const GET_BROKER = 'GET_BROKER';
export const UPDATE_BROKER = 'UPDATE_BROKER';
export const QUERY_BROKERS = 'QUERY_BROKERS';
export const EXECUTE_BROKER_ACTION = 'EXECUTE_BROKER_ACTION';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_BROKER_QUERY_ELEMENT';
export const REDUCE_BROKERS = 'REDUCE_BROKERS';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const GET_API_CREDENTIALS = 'GET_API_CREDENTIALS';
export const UPSERT_API_CREDENTIALS = 'UPSERT_API_CREDENTIALS';
export const SET_DEFAULT_API_CREDENTIALS = 'SET_DEFAULT_API_CREDENTIALS';
export const REDUCE_BROKER_API_CREDENTIAL = 'REDUCE_BROKER_API_CREDENTIAL';