import "core-js/stable";
import "regenerator-runtime/runtime";

import 'typeface-poppins';
import 'typeface-roboto';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { unregisterServiceWorker } from './registerServiceWorker';
import { HubConnectionBuilder } from '@microsoft/signalr';

import {
    ContextProvider,
    ReferenceProvider,
    DocumentProvider,
    NotificationProvider
} from 'metrix-common';

import App from './containers/App';
import CRMWebConfigurationProvider from './provider/CRMWebConfiguration';

import enMessages from './messages/en.json';
import frMessages from './messages/fr.json';

const initialState = window.initialReduxState;

const store = configureStore(initialState);

const rootElement = document.getElementById('app');

const hubConnection = new HubConnectionBuilder();

ReactDOM.render(
    <Provider store={store}>
        <ContextProvider messages={{ en: enMessages, fr: frMessages }}>
            <CRMWebConfigurationProvider>
                <NotificationProvider hubConnection={hubConnection}>
                    <ReferenceProvider>
                        <DocumentProvider>
                            <App />
                        </DocumentProvider>
                    </ReferenceProvider>
                </NotificationProvider>
            </CRMWebConfigurationProvider>
        </ContextProvider>
    </Provider>,
    rootElement);

unregisterServiceWorker();
