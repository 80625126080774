import dotProp from 'dot-prop-immutable';

import {
    REDUCE_BROKER_API_CREDENTIAL,
    REDUCE_BROKER_ELEMENT,
    REDUCE_BROKER,
    REDUCE_QUERY_ELEMENT,
    REDUCE_BROKERS,
    REDUCE_ENTITY
} from '../../constants/actions/broker';

const initialState = {
    entity: null,
    broker: null,
    query: {
        take: 25,
        skip: 0,
        orderByDesc: true
    },
    brokers: []
};

function brokerReducer(state = initialState, action) {
    let elementIndex = 0;
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, `entity`, action.payload);
        case REDUCE_BROKER_ELEMENT:
            return dotProp.set(state, `broker.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKER_API_CREDENTIAL:
            elementIndex = state.broker.apiCredentials.findIndex(ele => ele.id === action.payload.value.id);
            if(elementIndex >= 0){ 
                return dotProp.set(state, `broker.apiCredentials.${elementIndex}`, action.payload.value);
            } else {
                let lengthOfCredential = state.broker.apiCredentials?.length
                return dotProp.set(state, `broker.apiCredentials.${lengthOfCredential ? lengthOfCredential : `0`}`, action.payload.value);
            }
        case REDUCE_BROKER:
            return dotProp.set(state, 'broker', action.payload);
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKERS:
            return dotProp.set(state, 'brokers', action.payload);
        default:
            return state;
    }
}

export default brokerReducer;
