import React from 'react';
import { Colors, HeaderBar } from 'metrix-common';

import configs from './configs.json';

class AppBar extends React.Component {

    render() {
        const config = configs.filter(config => config.auth === this.props.context.authenticated && ((this.props.context.session && (config.userType === this.props.context.session.userType)) || config.userType === 'any'))[0];
        
        return (
            <React.Fragment>
                <HeaderBar context={this.props.context}
                    userName={this.props.context.session && this.props.context.session.firstName && this.props.context.session.firstName}
                    notification={this.props.context.session && this.props.context.session.userType === "administrator" && this.props.notification}
                    noOfElements={2}
                    color={Colors.PrimaryText}
                    logoWidth={`177`}
                    logoHeight={`50`}
                    companyNameColor={Colors.PrimaryText}
                    userTypeColor="red"
                    brandAvatarColor={Colors.Primary}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

export default AppBar;
