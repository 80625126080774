export const REDUCE_CLIENT_ELEMENT = 'REDUCE_CLIENT_ELEMENT';
export const REDUCE_CLIENT = 'REDUCE_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const QUERY_CLIENTS = 'QUERY_CLIENTS';
export const QUERY_CLIENT_REFS = 'QUERY_CLIENT_REFS';
export const EXECUTE_CLIENT_ACTION = 'EXECUTE_CLIENT_ACTION';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_CLIENT_QUERY_ELEMENT';
export const REDUCE_CLIENTS = 'REDUCE_CLIENTS';
export const REDUCE_CLIENT_REFS = 'REDUCE_CLIENT_REFS';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const REDUCE_PARAMETERS = 'REDUCE_PARAMETERS';
export const REDUCE_PARAMETER = 'REDUCE_PARAMETER';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REDUCE_NOTIFICATION = 'REDUCE_NOTIFICATION';
