import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CLIENT_ELEMENT,
    REDUCE_CLIENT,
    REDUCE_CLIENT_REFS,
    REDUCE_QUERY_ELEMENT,
    REDUCE_CLIENTS,
    REDUCE_PARAMETERS,
    REDUCE_PARAMETER,
    REDUCE_ENTITY
} from '../../constants/actions/client';

const initialState = {
    entity: null,
    parameters: [],
    client: null,
    clientRefs: null,
    query: {
        skip: 0,
        orderByDesc: true
    },
    clients: []
};

function clientReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, `entity`, action.payload);
        case REDUCE_PARAMETER:
            let parameterIndex = 0;
            if (state.parameters.some(x => x.code === action.payload.code)) {
                parameterIndex = state.parameters.findIndex(x => x.code === action.payload.code);
            } else {
                parameterIndex = state.parameters.length;
            }
            return dotProp.set(state, `parameters.${parameterIndex}`, { code: action.payload.code, value: action.payload.value });
        case REDUCE_PARAMETERS:
            return dotProp.set(state, `parameters`, action.payload);
        case REDUCE_CLIENT_ELEMENT:
            return dotProp.set(state, `client.${action.payload.code}`, action.payload.value);
        case REDUCE_CLIENT:
            return dotProp.set(state, 'client', action.payload);
        case REDUCE_CLIENT_REFS:
            return dotProp.set(state, 'clientRefs', action.payload);
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_CLIENTS:
            return dotProp.set(state, 'clients', action.payload);
        default:
            return state;
    }
}

export default clientReducer;
