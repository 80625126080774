export const REDUCE_BROKERCOMPANY_ELEMENT = 'REDUCE_BROKERCOMPANY_ELEMENT';
export const REDUCE_BROKERCOMPANY = 'REDUCE_BROKERCOMPANY';
export const GET_BROKERCOMPANY = 'GET_BROKERCOMPANY';
export const UPDATE_BROKERCOMPANY = 'UPDATE_BROKERCOMPANY';
export const QUERY_BROKERCOMPANIES = 'QUERY_BROKERCOMPANIES';
export const EXECUTE_BROKERCOMPANY_ACTION = 'EXECUTE_BROKERCOMPANY_ACTION';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_BROKER_COMPANY_QUERY_ELEMENT';
export const REDUCE_BROKERCOMPANIES = 'REDUCE_BROKERCOMPANIES';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';

export const GET_PARTNER_REFERENCES = 'GET_PARTNER_REFERENCES';
export const UPSERT_PARTNER_REFERENCES = 'UPSERT_PARTNER_REFERENCES';
export const REDUCE_BROKERCOMPANY_PARTNER_REFERENCE = 'REDUCE_BROKERCOMPANY_PARTNER_REFERENCE';

export const GET_BROKERCOMPANY_FEES = 'GET_BROKERCOMPANY_FEES';
export const UPSERT_BROKERCOMPANY_FEE = 'UPSERT_BROKERCOMPANY_FEE';
export const REDUCE_BROKERCOMPANY_FEE = 'REDUCE_BROKERCOMPANY_FEE';

export const GET_BROKERCOMPANY_PRODUCTS = 'GET_BROKERCOMPANY_PRODUCTS';
export const UPSERT_BROKERCOMPANY_PRODUCT = 'UPSERT_BROKERCOMPANY_PRODUCT';
export const DELETE_BROKERCOMPANY_PRODUCT = 'DELETE_BROKERCOMPANY_PRODUCT';
export const REDUCE_BROKERCOMPANY_PRODUCT = 'REDUCE_BROKERCOMPANY_PRODUCT';