export const LOGIN = 'LOGIN';
export const ACTIVATE = 'ACTIVATE';
export const REISSUE = 'REISSUE';
export const SIGN_UP = 'SIGN_UP';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const REDUCE_SECURITY_ELEMENT = 'REDUCE_SECURITY_ELEMENT';
export const REDUCE_SECURITY = 'REDUCE_SECURITY';
export const REACTIVATE = 'REACTIVATE';
export const DEACTIVATE = 'DEACTIVATE';